import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CompanyHero from "../components/companyHero"
import TextBox from "../components/textBox"
import CommunityButton from "../components/communityButton"

import Grid from "../components/grid"
import NewsSection from "../components/newsSection"
import ContactSection from "../components/contactSection"

const CompanyTemplate = ({ data }) => {
  const company = data.contentfulCompany
  let family = []
  if (company.founders) {
    family = family.concat(company.founders)
  }
  if (company.investors) {
    family = family.concat(company.investors)
  }

  return (
    <Layout>
      <SEO title={company.name} description={company.shortDescription} />
      <CompanyHero
        name={company.name}
        description={company.description ? company.description.description : ""}
        logo={company.logo}
        location={company.location}
        type={company.type}
        status={company.status}
        website={company.website}
        socialLinks={company.socialLinks}
      />
      {family.length > 0 && (
        <Grid header={`The ${company.name} Community`} tiles={family} />
      )}
      {company.qa && (
        <TextBox
          header="Founder Q&A"
          textBlockList={company.qa.textBlockList}
        />
      )}
      {company.newsthoughts && (
        <NewsSection
          title={`Latest ${company.name} News & Thoughts`}
          news={company.newsthoughts}
        />
      )}
      <CommunityButton />
      <ContactSection />
    </Layout>
  )
}

export default CompanyTemplate

export const query = graphql`
  query CompanyBySlug($slug: String!) {
    contentfulCompany(slug: { eq: $slug }) {
      name
      description {
        description
      }
      logo {
        fluid(maxWidth: 464, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      location
      status
      website
      socialLinks {
        twitter
        linkedIn
        blog
        facebook
        instagram
        youTube
      }
      founders {
        ...PersonTile
      }
      investors {
        ...PersonTile
      }
      qa {
        textBlockList {
          title
          body {
            body
          }
        }
      }
      newsthoughts {
        source
        description
        link
      }
    }
  }
`
