import React from "react"
import Img from "gatsby-image"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import styles from "../styles/components/company-hero.module.scss"
//import sentenaiLogo from "../images/sentenai-logo-2.png"
import Flag1 from "../images/company-hero-flag-1.svg"
import Flag2 from "../images/company-hero-flag-2.svg"
import Flag3 from "../images/company-hero-flag-3.svg"
import Flag4 from "../images/company-hero-flag-4.svg"

import TwitterIcon from "../images/twitter-icon.svg"
import LinkedinIcon from "../images/linkedin-icon.svg"
import InstagramIcon from "../images/instagram-icon.svg"
import FacebookIcon from "../images/facebook-icon.svg"
import YouTubeIcon from "../images/youtube-icon.svg"

const CompanyHero = props => {
  return (
    <div className={styles.background}>
      <ReactCSSTransitionGroup
        transitionName="flag-1-company"
        /* add an extra transition phase at the initial mount of the component */
        transitionAppear={true}
        transitionAppearTimeout={600} /* timeout = delay + duration in css */
        transitionEnter={false}
        transitionLeave={false}
      >
        <Flag1 id={styles.flag1} />
      </ReactCSSTransitionGroup>
      <ReactCSSTransitionGroup
        transitionName="flag-2-company"
        transitionAppear={true}
        transitionAppearTimeout={750}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Flag2 id={styles.flag2} />
      </ReactCSSTransitionGroup>
      <ReactCSSTransitionGroup
        transitionName="flag-3-company"
        transitionAppear={true}
        transitionAppearTimeout={750}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Flag3 id={styles.flag3} />
      </ReactCSSTransitionGroup>
      <ReactCSSTransitionGroup
        transitionName="flag-4-company"
        transitionAppear={true}
        transitionAppearTimeout={750}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Flag4 id={styles.flag4} />
      </ReactCSSTransitionGroup>
      <div className={styles.contentContainer}>
        <div className={styles.imageContainer}>
          <div className={styles.logoBox}>
            <Img
              fluid={props.logo.fluid}
              alt={`${props.name} Logo`}
              className={styles.image}
            />
          </div>
        </div>
        <div className={styles.textContainer}>
          <div className={styles.title}>{props.name}</div>
          <div className={styles.description}>{props.description}</div>
          <div className={styles.info}>
            <div className={styles.column}>
              {props.location && <div className={styles.label}>Location</div>}
              {props.location && (
                <div className={styles.value}>{props.location}</div>
              )}
              {props.website && <div className={styles.label}>Website</div>}
              {props.website && (
                <div className={styles.value}>
                  <a
                    href={props.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {
                      props.website
                        .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                        .split("/")[0]
                    }
                  </a>
                </div>
              )}
            </div>
            <div className={styles.column}>
              {props.status && <div className={styles.label}>Status</div>}
              {props.status && (
                <div className={styles.value}>{props.status}</div>
              )}
              {props.socialLinks && <div className={styles.label}>Social</div>}
              {props.socialLinks && (
                <div className={styles.socialIcons}>
                  {props.socialLinks.twitter && (
                    <a
                      href={props.socialLinks.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TwitterIcon />
                    </a>
                  )}
                  {props.socialLinks.linkedIn && (
                    <a
                      href={props.socialLinks.linkedIn}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedinIcon />
                    </a>
                  )}
                  {props.socialLinks.instagram && (
                    <a
                      href={props.socialLinks.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon />
                    </a>
                  )}
                  {props.socialLinks.facebook && (
                    <a
                      href={props.socialLinks.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookIcon />
                    </a>
                  )}
                  {props.socialLinks.youTube && (
                    <a
                      href={props.socialLinks.youTube}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <YouTubeIcon />
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyHero
